import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from './AuthContext';
import ContentEditor from './ContentEditor';
import { Button } from '@mui/material';
import BecomeMemberForm from './schemas/BecomeMemberSchema/BecomeMemberForm';

const generateCaptcha = () => {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let captcha = '';
  for (let i = 0; i < 6; i++) {
    captcha += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return captcha;
};

const BecomeMember = () => {
  const [content, setContent] = useState({ title: '', content: '' });
  const [isEditing, setIsEditing] = useState(false);
  const { user } = useContext(AuthContext);

  const [formData, setFormData] = useState({
    firstName: '',
    birthDate: '',
    lastName: '',
    boatName: '',
    address: '',
    boatRegNr: '',
    postalCode: '',
    insuranceCompany: '',
    city: '',
    email: '',
    phone: '',
    mobile: '',
    emergencyContact: '',
    emergencyMobile: '',
    date: '',
    place: '',
    signature: '',
    comment: '',
    confirmRules: false,
    type: 'becomemember'
  });

  const [messageStatus, setMessageStatus] = useState({ type: '', message: '' });
  const [captcha, setCaptcha] = useState(generateCaptcha());
  const [captchaInput, setCaptchaInput] = useState('');

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/content/becomemember`);
        setContent(response.data);
      } catch (err) {
        console.error('Failed to fetch content', err);
      }
    };
    fetchContent();
  }, []);

  const handleSave = (updatedContent) => {
    if (updatedContent) setContent(updatedContent);
    setIsEditing(false);
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({ ...prev, [name]: type === 'checkbox' ? checked : value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (captchaInput !== captcha) {
      setMessageStatus({ type: 'error', message: 'Feil kode. Vennligst prøv igjen.' });
      return;
    }

    const fullName = `${formData.firstName} ${formData.lastName}`.trim();
    const timestamp = Date.now().toString();

    let signature = '';
    try {
      const sigRes = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/form/signature/${timestamp}`);
      signature = sigRes.data.signature;
    } catch (err) {
      console.error('Klarte ikke hente signatur fra backend:', err);
      setMessageStatus({ type: 'error', message: 'Klarte ikke hente signatur. Prøv igjen senere.' });
      return;
    }

    const today = new Date().toISOString().split('T')[0];
    const sanitizedName = fullName
      .replace(/\s+/g, '_')
      .replace(/[^a-zA-Z0-9_æøåÆØÅ-]/g, '');
    const pdfFileName = `medlemssøknad_${sanitizedName}_${today}.pdf`;

    const payload = {
      name: fullName,
      email: formData.email,
      type: formData.type,
      formData,
      timestamp,
      signature,
      pdfFileName
    };

    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/form`, payload);
      setMessageStatus({ type: 'success', message: 'Søknaden din har blitt sendt!' });

      setFormData({
        firstName: '',
        birthDate: '',
        lastName: '',
        boatName: '',
        address: '',
        boatRegNr: '',
        postalCode: '',
        insuranceCompany: '',
        city: '',
        email: '',
        phone: '',
        mobile: '',
        emergencyContact: '',
        emergencyMobile: '',
        date: '',
        place: '',
        signature: '',
        comment: '',
        confirmRules: false,
        type: 'becomemember'
      });
      setCaptcha(generateCaptcha());
      setCaptchaInput('');
    } catch (err) {
      console.error('Feil ved innsending:', err.response || err.message);
      setMessageStatus({
        type: 'error',
        message: 'Kunne ikke sende søknaden. Vennligst prøv igjen senere.'
      });
    }
  };

  return (
    <div className="main-content">
      {isEditing ? (
        <ContentEditor
          contentId="becomemember"
          initialTitle={content.title}
          initialContent={content.content}
          onSave={handleSave}
        />
      ) : (
        <>
          <h2>{content.title}</h2>
          <div dangerouslySetInnerHTML={{ __html: content.content }} />
          {user && (
            <Button variant="outlined" color="primary" onClick={() => setIsEditing(true)}>
              Endre
            </Button>
          )}
          <BecomeMemberForm
            formData={formData}
            onChange={handleInputChange}
            onSubmit={handleSubmit}
            messageStatus={messageStatus}
            captcha={captcha}
            captchaInput={captchaInput}
            onCaptchaInputChange={(e) => setCaptchaInput(e.target.value)}
          />
        </>
      )}
    </div>
  );
};

export default BecomeMember;
