import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../styles/Sidebar.css';
import highTideIcon from '../assets/high-tide.svg';
import lowTideIcon from '../assets/low-tide.svg';

const TideTimesWidget = () => {
  const [currentTide, setCurrentTide] = useState(null);
  const [nextTide, setNextTide] = useState(null);
  const [nextNextTide, setNextNextTide] = useState(null);
  const [isFlooding, setIsFlooding] = useState(true);

  useEffect(() => {
    const fetchTideData = async () => {
      const today = new Date().toISOString().split('T')[0];
      const dayAfterTomorrow = new Date(new Date().setDate(new Date().getDate() + 2)).toISOString().split('T')[0];

      try {
        const response = await axios.get('https://vannstand.kartverket.no/tideapi.php', {
          params: {
            lat: 62.48569,
            lon: 6.69662,
            fromtime: `${today}T00:00`,
            totime: `${dayAfterTomorrow}T00:00`,
            datatype: 'tab',
            refcode: 'cd',
            lang: 'nb',
            interval: '60',
            dst: '0',
            tide_request: 'locationdata'
          }
        });

        const xml = new window.DOMParser().parseFromString(response.data, "text/xml");
        const tideElements = xml.getElementsByTagName('waterlevel');
        const tideArray = Array.from(tideElements).map(elem => ({
          time: new Date(elem.getAttribute('time')),
          value: elem.getAttribute('value'),
          flag: elem.getAttribute('flag')
        }));

        determineCurrentAndNextTide(tideArray);
      } catch (error) {
        console.error("Error fetching tide data:", error);
        if (error.response) {
          console.error("Response data:", error.response.data);
        }
      }
    };

    const determineCurrentAndNextTide = (tideArray) => {
      const now = new Date();
      for (let i = 0; i < tideArray.length; i++) {
        if (tideArray[i].time > now) {

          setNextTide(tideArray[i]);
          setCurrentTide(tideArray[i - 1]);
          if (i + 1 < tideArray.length) {
            setNextNextTide(tideArray[i + 1]);
          } else {
            setNextNextTide(null);
          }
          setIsFlooding(tideArray[i - 1]?.flag === 'low');
          break;
        }
      }
    };

    fetchTideData();
  }, []);

  if (!currentTide || !nextTide) {
    return <div>Laster tidevann...</div>;
  }

  return (
    <div className="tide-widget-container">
      <div className="tide-widget">
        <img 
          src={isFlooding ? highTideIcon : lowTideIcon} 
          alt={isFlooding ? 'High Tide' : 'Low Tide'} 
          className="tide-widget-icon" 
        />
        <div className="tide-widget-info">
          <span className="tide-widget-status" style={{ fontWeight: 'bold' }}>
            {isFlooding
              ? `Flør nå til ${nextTide.value} cm`
              : `Fjører nå til ${nextTide.value} cm`}
          </span>
          <span className="tide-widget-next">
            Neste {isFlooding ? 'høyvann' : 'lavvann'} kl.{' '}
            {nextTide.time.toLocaleTimeString('no-NO', {
              hour: '2-digit',
              minute: '2-digit'
            })}
          </span>
          {nextNextTide && (
            <span className="tide-widget-next">
              ({isFlooding ? 'Lavvann' : 'Høyvann'} kl.{' '}
              {nextNextTide.time.toLocaleTimeString('no-NO', {
                hour: '2-digit',
                minute: '2-digit'
              })})
            </span>
          )}
        </div>
      </div>
    </div>
  );
  
  
};

export default TideTimesWidget;
