import React from 'react';
import {
  TextField,
  Grid,
  Button,
  Typography,
  Paper,
  Alert
} from '@mui/material';

const WindAlertForm = ({
  formData,
  onChange,
  onSubmit,
  onUnsubscribe,
  messageStatus
}) => {
  return (
    <Paper
      elevation={3}
      style={{ padding: '32px', marginTop: '32px', backgroundColor: '#fff' }}
    >
      <Typography variant="h5" gutterBottom>
        Abonner på vindvarsling
      </Typography>
      <Typography variant="body1" gutterBottom>
        Fyll ut navn og e-post for å motta e-postvarsel når det registreres over 20 m/s på havna.
        Du kan når som helst melde deg av.
      </Typography>

      <form onSubmit={onSubmit}>
        <Grid container spacing={2}>
          {/* Navn */}
          <Grid item xs={12}>
            <TextField
              name="name"
              label="Ditt navn"
              fullWidth
              required
              value={formData.name}
              onChange={onChange}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              name="email"
              label="E-post"
              fullWidth
              required
              value={formData.email}
              onChange={onChange}
            />
          </Grid>
        </Grid>

        <Button
          type="submit"
          variant="contained"
          color="primary"
          style={{ marginTop: '16px' }}
        >
          Abonner på vindvarsling
        </Button>

        <Button
          onClick={onUnsubscribe}
          variant="outlined"
          color="secondary"
          style={{ marginTop: '16px', marginLeft: '12px' }}
        >
          Meld av varsling
        </Button>

        {messageStatus.message && (
          <Alert severity={messageStatus.type} style={{ marginTop: '16px' }}>
            {messageStatus.message}
          </Alert>
        )}
      </form>
    </Paper>
  );
};

export default WindAlertForm;
