import React, { useState, useEffect, useContext } from 'react';
import {
  Button, Paper, Typography, Grid, Collapse, List, ListItem, ListItemText, ListItemAvatar,
  Avatar, IconButton, Dialog, DialogTitle, DialogContent, DialogActions,
  CircularProgress, Alert, TextField, Box
} from '@mui/material';
import { AuthContext } from './AuthContext';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import UploadIcon from '@mui/icons-material/Upload';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import '../styles/Schemas.css';
import axios from 'axios';

import BecomeMember from './schemas/BecomeMemberSchema';
import ContactForm from './schemas/ContactSchema';
import StorageHallForm from './schemas/StorageHallSchema';
import WindAlertForm from './schemas/SubscribeWindAlerts';

const Schemas = () => {
  const { user } = useContext(AuthContext);
  const [selectedForm, setSelectedForm] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [openUploadDialog, setOpenUploadDialog] = useState(false);
  const [file, setFile] = useState(null);
  const [editingFile, setEditingFile] = useState(null);
  const [newFileName, setNewFileName] = useState('');

  const forms = [
    { code: 'BecomeMember', title: 'Bli medlem' },
    { code: 'ContactForm', title: 'Kontaktskjema' },
    { code: 'StorageHall', title: 'Søknad om plass i lagringshall' },
    { code: 'WindAlert', title: 'Meld deg på vindvarsler' }
  ];

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/documents`);
        setDocuments(response.data);
      } catch (err) {
        setError('Feil ved henting av dokumenter');
      } finally {
        setLoading(false);
      }
    };
    fetchDocuments();
  }, []);

  const handleToggleForm = (code) => {
    setSelectedForm(prev => prev === code ? null : code);
  };

  const handleUpload = async () => {
    if (!file) return;
    const formData = new FormData();
    formData.append('document', file);
    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/documents`, formData, {
        headers: {
          Authorization: `Bearer ${user.token}`,
          'Content-Type': 'multipart/form-data'
        }
      });
      setOpenUploadDialog(false);
      setFile(null);
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/documents`);
      setDocuments(response.data);
    } catch {
      setError('Feil ved opplasting av fil');
    }
  };

  const handleDelete = async (filename) => {
    try {
      await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/documents/delete/${filename}`, {
        headers: { Authorization: `Bearer ${user.token}` }
      });
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/documents`);
      setDocuments(response.data);
    } catch {
      setError('Feil ved sletting av fil');
    }
  };

  const handleEdit = (document) => {
    setEditingFile(document);
    setNewFileName(document.name.replace(/\.[^/.]+$/, ""));
  };

  const handleRename = async () => {
    const ext = editingFile.name.substring(editingFile.name.lastIndexOf('.'));
    try {
      await axios.put(`${process.env.REACT_APP_BACKEND_URL}/documents/rename/${editingFile.name}`, { newName: `${newFileName}${ext}` }, {
        headers: { Authorization: `Bearer ${user.token}` }
      });
      setEditingFile(null);
      setNewFileName('');
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/documents`);
      setDocuments(response.data);
    } catch {
      setError('Feil ved endring av navn på fil');
    }
  };

  const getIconByFileType = (fileName) => {
    const ext = fileName.split('.').pop().toLowerCase();
    return ext === 'pdf' ? <PictureAsPdfIcon /> : <InsertDriveFileIcon />;
  };

  const renderForm = () => {
    switch (selectedForm) {
      case 'BecomeMember': return <BecomeMember />;
      case 'ContactForm': return <ContactForm />;
      case 'StorageHall': return <StorageHallForm />;
      case 'WindAlert': return <WindAlertForm />;
      default: return null;
    }
  };

  return (
    <div className="main-content">
      <h2>Skjema og dokument</h2>

      <Typography variant="h5" gutterBottom style={{ marginTop: '32px' }}>
        Skjema
      </Typography>
      <Grid container spacing={3}>
        {forms.map(form => (
          <Grid item key={form.code} xs={12} sm={6} md={4}>
            <Paper elevation={3} className="schemas-paper">
              <Typography variant="h6" gutterBottom>{form.title}</Typography>
              <Button variant="contained" color="primary" onClick={() => handleToggleForm(form.code)}>
                {selectedForm === form.code ? 'Lukk skjema' : 'Åpne skjema'}
              </Button>
            </Paper>
          </Grid>
        ))}
      </Grid>

      <Collapse in={selectedForm !== null}>
        <div className="schemas-form-container">
          {renderForm()}
        </div>
      </Collapse>

      <Typography variant="h5" gutterBottom style={{ marginTop: '32px' }}>
        Dokumenter
      </Typography>
      {loading ? <CircularProgress /> :
        error ? <Alert severity="error">{error}</Alert> :
          <List>
            {documents.map(doc => (
              <ListItem key={doc.name}>
                <ListItemAvatar>
                  <Avatar>{getIconByFileType(doc.name)}</Avatar>
                </ListItemAvatar>
                <ListItemText primary={
                  <a href={`${process.env.REACT_APP_BACKEND_URL}${doc.path}`} target="_blank" rel="noopener noreferrer">
                    {doc.name.replace(/\.[^/.]+$/, "")}
                  </a>
                } />
                {user && (
                  <>
                    <IconButton onClick={() => handleEdit(doc)}><EditIcon /></IconButton>
                    <IconButton onClick={() => handleDelete(doc.name)}><DeleteIcon /></IconButton>
                  </>
                )}
              </ListItem>
            ))}
          </List>
      }

      {user && (
        <Box sx={{ mt: 2 }}>
          <Button variant="contained" color="primary" startIcon={<UploadIcon />} onClick={() => setOpenUploadDialog(true)}>
            Last opp dokument
          </Button>
          <Dialog open={openUploadDialog} onClose={() => setOpenUploadDialog(false)}>
            <DialogTitle>Last opp dokument</DialogTitle>
            <DialogContent><input type="file" onChange={e => setFile(e.target.files[0])} /></DialogContent>
            <DialogActions>
              <Button onClick={handleUpload}>Last opp</Button>
              <Button onClick={() => setOpenUploadDialog(false)}>Avbryt</Button>
            </DialogActions>
          </Dialog>
        </Box>
      )}

      {editingFile && (
        <Dialog open={!!editingFile} onClose={() => setEditingFile(null)}>
          <DialogTitle>Endre navn på dokument</DialogTitle>
          <DialogContent>
            <TextField fullWidth label="Nytt navn" value={newFileName} onChange={(e) => setNewFileName(e.target.value)} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleRename}>Lagre</Button>
            <Button onClick={() => setEditingFile(null)}>Avbryt</Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

export default Schemas;
