import React from 'react';
import {
  TextField,
  Grid,
  Button,
  Typography,
  Paper,
  Alert
} from '@mui/material';

const ContactForm = ({
  formData,
  onChange,
  onSubmit,
  messageStatus,
  captcha,
  captchaInput,
  onCaptchaInputChange
}) => {
  return (
    <Paper elevation={3} style={{ padding: '32px', marginTop: '32px', backgroundColor: '#fff' }}>
      <Typography variant="h5" gutterBottom>
        Send en e-post til oss
      </Typography>

      <form onSubmit={onSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              name="name"
              label="Navn"
              fullWidth
              value={formData.name}
              onChange={onChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="email"
              label="E-post"
              type="email"
              fullWidth
              value={formData.email}
              onChange={onChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="message"
              label="Melding"
              fullWidth
              multiline
              rows={4}
              value={formData.message}
              onChange={onChange}
              required
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1" gutterBottom>
              For å unngå spam må du skrive inn koden under eksakt slik den står:
            </Typography>
            <Typography
              variant="h6"
              gutterBottom
              style={{
                fontFamily: 'monospace',
                backgroundColor: '#f0f0f0',
                padding: '8px',
                display: 'inline-block'
              }}
            >
              {captcha}
            </Typography>
            <TextField
              label="Eksakt kode som står over"
              fullWidth
              value={captchaInput}
              onChange={onCaptchaInputChange}
              required
              style={{ marginTop: '8px' }}
            />
          </Grid>
        </Grid>

        <Button
          type="submit"
          variant="contained"
          color="primary"
          style={{ marginTop: '16px' }}
        >
          Send
        </Button>

        {messageStatus.message && (
          <Alert severity={messageStatus.type} style={{ marginTop: '16px' }}>
            {messageStatus.message}
          </Alert>
        )}
      </form>
    </Paper>
  );
};

export default ContactForm;
