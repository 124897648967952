import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { io } from 'socket.io-client';
import Parameter from './Parameter';
import TrendPopup from './TrendPopup';
import TideTimes from './TideTimes';
import '../styles/WeatherWebcam.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import WeatherAlerts from './WeatherAlerts';
import WindAlert from './schemas/SubscribeWindAlerts';

import BF0 from '../assets/0.svg';
import BF1 from '../assets/1.svg';
import BF2 from '../assets/2.svg';
import BF3 from '../assets/3.svg';
import BF4 from '../assets/4.svg';
import BF5 from '../assets/5.svg';
import BF6 from '../assets/6.svg';
import BF7 from '../assets/7.svg';
import BF8 from '../assets/8.svg';
import BF9 from '../assets/9.svg';
import BF10 from '../assets/10.svg';
import BF11 from '../assets/11.svg';
import BF12 from '../assets/12.svg';

const svgMapping = {
  0: BF0, 1: BF1, 2: BF2, 3: BF3, 4: BF4, 5: BF5,
  6: BF6, 7: BF7, 8: BF8, 9: BF9, 10: BF10, 11: BF11, 12: BF12
};

const beaufortDescriptions = {
  0: 'Stille', 1: 'Flau vind', 2: 'Svak vind', 3: 'Lett bris',
  4: 'Laber bris', 5: 'Frisk bris', 6: 'Liten kuling', 7: 'Stiv kuling',
  8: 'Sterk kuling', 9: 'Liten storm', 10: 'Full storm',
  11: 'Sterk storm', 12: 'Orkan'
};

const extractNumber = (str) => {
  if (!str) return 0;
  return parseInt(str.replace(/\D/g, ''), 10);
};

const WeatherWebcam = () => {
  const [weather, setWeather] = useState({ Hahjem: {} });
  const [loaded, setLoaded] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [trendData, setTrendData] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedType, setSelectedType] = useState(null);

  useEffect(() => {
    const socket = io('https://api.sunnmorsver.no', { autoConnect: false });
    socket.connect();

    socket.on('connect_error', (err) => {
      console.error(`connect_error due to ${err.message}`);
    });

    socket.on('file-content', (data) => {
      setLoaded(true);
      setWeather((prevWeather) => ({
        ...prevWeather,
        Hahjem: { ...prevWeather.Hahjem, ...data.Hahjem },
      }));
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleFetchTrend = async (location, type) => {
    setSelectedLocation(location);
    setSelectedType(type);
    setShowPopup(true);
    try {
      const response = await axios.post('https://api.sunnmorsver.no/fetch24HourTrend', {
        station: 'Håhjem',
        type: type
      });
      setTrendData(response.data.data);
    } catch (error) {
      console.error("Error fetching trend data:", error);
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const currentbHahjem = svgMapping[extractNumber(weather.Hahjem.beaufort)];

  const parameters = [
    {
      icon: "fas fa-thermometer-half",
      label: "Temperatur:",
      data: [{ className: "temperature", value: `${weather.Hahjem.temp ?? 'N/A'} °C`, date: weather.Hahjem.date }]
    },
    {
      icon: "fas fa-chart-bar",
      label: "Barometer:",
      data: [{ className: "barometer", value: `${weather.Hahjem.press ?? 'N/A'} hPa`, date: weather.Hahjem.date }]
    },
    {
      icon: "fas fa-tint",
      label: "Luftfuktighet:",
      data: [{ className: "humidity", value: `${weather.Hahjem.hum ?? 'N/A'} %`, date: weather.Hahjem.date }]
    },
    {
      icon: "fas fa-cloud-showers-heavy",
      label: "Nedbør nå (totalt i dag):",
      data: [{ className: "rain", value: `${weather.Hahjem.rrate ?? 'N/A'} mm/t (${weather.Hahjem.rfall ?? 'N/A'} mm)`, date: weather.Hahjem.date }]
    },
    {
      icon: "fas fa-wind",
      label: "Sanntid vind nå (middelvind):",
      data: [{
        className: "currwind",
        value: `${weather.Hahjem.wlatest ?? 'N/A'} (${weather.Hahjem.wspeed ?? 'N/A'}) m/s`,
        imgSrc: currentbHahjem,
        description: beaufortDescriptions[extractNumber(weather.Hahjem.beaufort)],
        bearing: weather.Hahjem.bearing,
        date: weather.Hahjem.date
      }]
    },
    {
      icon: "fas fa-wind",
      label: "Høyeste vindkast i dag:",
      data: [{ className: "gustwind", value: `${weather.Hahjem.wgustTM ?? 'N/A'} m/s - klokken ${weather.Hahjem.TwgustTM ?? 'N/A'}`, date: weather.Hahjem.date }]
    }
  ];

  return (
    <div className="main-content">
      <WeatherAlerts />
      <h2>Vær og webkamera</h2>
      <div className="toptext">Klikk på verdiene for å vise trend de siste 24 timene.</div>

      <section className="left-align">
        {loaded ? (
          <div className="weather-card">
            <div className="parameter-box">
              {parameters.map((param, index) => (
                <Parameter
                  key={index}
                  icon={param.icon}
                  label={param.label}
                  data={param.data}
                  onFetchTrend={handleFetchTrend}
                />
              ))}
            </div>
          </div>
        ) : (
          <div className="loading">
            <i className="fas fa-spinner fa-spin" aria-hidden="true"></i>
            <span>Laster inn værdata...</span>
          </div>
        )}
      </section>

      <section className="alert-subscribe">
  <WindAlert />
</section>

      <section className="webcam-section">
        <img
          src={`https://skodjesbf.com/webcam/WebcamSSBF.jpg?ts=${Date.now()}`}
          alt="Webkamera"
          className="webcam-image"
        />
      </section>

      <section className="tide-section">
        <TideTimes />
      </section>

      {showPopup && (
        <TrendPopup
          selectedLocation={selectedLocation}
          selectedType={selectedType}
          trendData={trendData}
          onClose={handleClosePopup}
        />
      )}
    </div>
  );
};

export default WeatherWebcam;
