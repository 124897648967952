import React, { useState, useEffect, useContext, useRef } from 'react';
import axios from 'axios';
import { AuthContext } from './AuthContext';
import { TextField, Button, Container, Typography, Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemAvatar, ListItemText, Avatar, IconButton } from '@mui/material';
import JoditEditor from 'jodit-react';
import ImageIcon from '@mui/icons-material/Image';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DeleteIcon from '@mui/icons-material/Delete';
import 'draft-js/dist/Draft.css';
import '../styles/ContentEditor.css';

const ContentEditor = ({ contentId, initialTitle = '', initialContent = '', onSave, isNews = false }) => {
  const [title, setTitle] = useState(initialTitle);
  const [content, setContent] = useState(initialContent);
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [openFileBrowser, setOpenFileBrowser] = useState(false);
  const [files, setFiles] = useState([]);
  const [error, setError] = useState('');
  const isMounted = useRef(false);
  const editor = useRef(null);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (initialContent && isMounted.current) {
      setContent(initialContent);
    }
  }, [initialContent]);

  useEffect(() => {
    const source = axios.CancelToken.source();

    const fetchContent = async () => {
      if (contentId && contentId !== 'new' && !isNews) {
        setLoading(true);
        try {
          const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/content/${contentId}`, {
            cancelToken: source.token,
          });
          if (isMounted.current) {
            setTitle(response.data.title);
            setContent(response.data.content);
          }
        } catch (err) {
          if (axios.isCancel(err)) {
          } else {
            if (isMounted.current) {
              console.error('Failed to fetch content', err);
            }
          }
        } finally {
          if (isMounted.current) {
            setLoading(false);
          }
        }
      }
    };

    fetchContent();

    return () => {
      source.cancel('Component unmounted, canceling request');
    };
  }, [contentId, isNews]);

  const processInput = (input) => {
    if (typeof input === 'string') {
      return input;
    } else {
      console.error('Expected a string but received:', typeof input);
      return '';
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!user || !user.token) {
      console.error('User not authenticated or token missing');
      return;
    }
  
    const normalizedTitle = processInput(title);
    const normalizedContent = processInput(content);
  
    try {
      if (isNews) {
        const payload = { title: normalizedTitle, content: normalizedContent };
  
        // Ikke gjør noen nettverksforespørsel her – gi data tilbake til Memberinfo
        if (onSave && isMounted.current) {
          onSave(payload);
        }
  
        return; // Avslutt før noe sendes
      }
  
      // Rediger statisk innhold (ikke nyhet)
      await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/content/${contentId}`,
        { title: normalizedTitle, content: normalizedContent },
        {
          headers: { Authorization: `Bearer ${user.token}` }
        }
      );
  
      if (onSave && isMounted.current) {
        onSave({ id: contentId, title: normalizedTitle, content: normalizedContent });
      }
  
    } catch (err) {
      if (isMounted.current) {
        console.error('Failed to update content', err.response ? err.response.data : err.message);
      }
    }
  };
  

  const handleCancel = () => {
    if (onSave && isMounted.current) {
      onSave(null);
    }
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      const filePath = `${process.env.REACT_APP_BACKEND_URL}${response.data.filePath}`;
      const ext = file.name.split('.').pop().toLowerCase();
      if (['jpg', 'jpeg', 'png', 'gif'].includes(ext)) {
        setContent(content + `<img src="${filePath}" alt="image" />`);
      } else {
        setContent(content + `<a href="${filePath}" target="_blank">${file.name}</a>`);
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setError(error.response.data.error);
      } else {
        console.error('Failed to upload file', error);
      }
    }
  };

  const handleFileDelete = async (fileName) => {
    try {
      await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/upload/delete/${fileName}`);
      setFiles(files.filter(file => file.name !== fileName));
    } catch (error) {
      console.error('Failed to delete file', error);
    }
  };

  const handleFileBrowserOpen = () => {
    setOpenFileBrowser(true);
    fetchFiles();
  };

  const fetchFiles = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/upload`);
      setFiles(response.data);
    } catch (error) {
      console.error('Failed to fetch files', error);
    }
  };

  const handleFileSelect = (filePath) => {
    const ext = filePath.split('.').pop().toLowerCase();
    if (['jpg', 'jpeg', 'png', 'gif'].includes(ext)) {
      setContent(content + `<img src="${process.env.REACT_APP_BACKEND_URL}${filePath}" alt="image" />`);
    } else {
      setContent(content + `<a href="${process.env.REACT_APP_BACKEND_URL}${filePath}" target="_blank">${filePath}</a>`);
    }
    setOpenFileBrowser(false);
  };

  const getThumbnail = (file) => {
    const ext = file.name.split('.').pop().toLowerCase();
    return (
      <ListItem
        key={file.name}
        button
        onClick={() => handleFileSelect(file.path)}
        secondaryAction={
          <IconButton edge="end" aria-label="delete" onClick={() => handleFileDelete(file.name)}>
            <DeleteIcon />
          </IconButton>
        }
      >
        <ListItemAvatar>
          {['jpg', 'jpeg', 'png', 'gif'].includes(ext) ? (
            <Avatar src={`${process.env.REACT_APP_BACKEND_URL}${file.path}`} alt={file.name} variant="square" />
          ) : ext === 'pdf' ? (
            <Avatar>
              <PictureAsPdfIcon />
            </Avatar>
          ) : (
            <Avatar>
              <ImageIcon />
            </Avatar>
          )}
        </ListItemAvatar>
        <ListItemText primary={file.name} />
      </ListItem>
    );
  };

  const config = {
    readonly: false,
  };

  return user ? (
    <Container maxWidth="sm">
      <Box sx={{ mt: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Endre innhold
        </Typography>
        {loading ? (
          <CircularProgress />
        ) : (
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              label="Tittel"
              variant="outlined"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              margin="normal"
            />
            <JoditEditor
              ref={editor}
              value={content}
              config={config}
              tabIndex={1}
              onBlur={newContent => setContent(newContent)}
              onChange={newContent => {}}
              className="jodit-editor"
            />
            <input type="file" onChange={handleFileUpload} />
            {error && <Typography color="error">{error}</Typography>}
            <Button variant="outlined" onClick={handleFileBrowserOpen}>
              Bla gjennom filer på serveren
            </Button>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
              <Button type="submit" variant="contained" color="primary">
                Publiser
              </Button>
              <Button variant="outlined" color="secondary" onClick={handleCancel}>
                Avbryt
              </Button>
            </Box>
          </form>
        )}
      </Box>
      <Dialog open={openFileBrowser} onClose={() => setOpenFileBrowser(false)}>
        <DialogTitle>Se gjennom filer</DialogTitle>
        <DialogContent>
          <List>
            {files.map((file, index) => getThumbnail(file))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenFileBrowser(false)} color="primary">
            Avbryt
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  ) : (
    <Typography variant="h6" color="error">
      Vennligst logg inn for å endre innhold
    </Typography>
  );
};

export default ContentEditor;
