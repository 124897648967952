import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from './AuthContext';
import ContentEditor from './ContentEditor';
import { Button } from '@mui/material';
import ContactForm from './schemas/ContactSchema/ContactForm';

const generateCaptcha = () => {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let captcha = '';
  for (let i = 0; i < 6; i++) {
    captcha += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return captcha;
};

const Contact = () => {
  const [content, setContent] = useState({ title: '', content: '' });
  const [isEditing, setIsEditing] = useState(false);
  const { user } = useContext(AuthContext);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    type: 'contact'
  });

  const [captcha, setCaptcha] = useState(generateCaptcha());
  const [captchaInput, setCaptchaInput] = useState('');
  const [messageStatus, setMessageStatus] = useState({ type: '', message: '' });

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/content/contact`);
        setContent(response.data);
      } catch (err) {
        console.error('Failed to fetch content', err);
      }
    };
    fetchContent();
  }, []);

  const handleSave = (updatedContent) => {
    if (updatedContent) setContent(updatedContent);
    setIsEditing(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (captchaInput !== captcha) {
      setMessageStatus({ type: 'error', message: 'Feil kode. Vennligst prøv igjen.' });
      return;
    }

    const timestamp = Date.now().toString();
    let signature = '';

    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/form/signature/${timestamp}`);
      signature = response.data.signature;
    } catch (err) {
      console.error('Kunne ikke hente signatur fra backend:', err.message || err);
      setMessageStatus({
        type: 'error',
        message: 'Intern feil. Prøv igjen senere.'
      });
      return;
    }

    const payload = {
      name: formData.name,
      email: formData.email,
      type: 'contact',
      formData,
      timestamp,
      signature
    };

    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/form`, payload);
      setMessageStatus({ type: 'success', message: 'Meldingen din har blitt sendt!' });

      setFormData({ name: '', email: '', message: '', type: 'contact' });
      setCaptcha(generateCaptcha());
      setCaptchaInput('');
    } catch (err) {
      console.error('Feil ved innsending:', err.response || err.message);
      setMessageStatus({
        type: 'error',
        message: 'Kunne ikke sende meldingen. Vennligst prøv igjen senere.'
      });
    }
  };

  return (
    <div className="main-content">
      {isEditing ? (
        <ContentEditor
          contentId="contact"
          initialTitle={content.title}
          initialContent={content.content}
          onSave={handleSave}
        />
      ) : (
        <>
          <h2>{content.title}</h2>
          <div dangerouslySetInnerHTML={{ __html: content.content }} />
          {user && (
            <Button variant="outlined" color="primary" onClick={() => setIsEditing(true)}>
              Endre
            </Button>
          )}
          <ContactForm
            formData={formData}
            onChange={handleInputChange}
            onSubmit={handleSubmit}
            messageStatus={messageStatus}
            captcha={captcha}
            captchaInput={captchaInput}
            onCaptchaInputChange={(e) => setCaptchaInput(e.target.value)}
          />
        </>
      )}
    </div>
  );
};

export default Contact;
