import React, { useState } from 'react';
import {
  TextField,
  Grid,
  Button,
  Typography,
  Paper,
  Alert
} from '@mui/material';

const generateCaptcha = () => {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let captcha = '';
  for (let i = 0; i < 6; i++) {
    captcha += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return captcha;
};

const BecomeMemberForm = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    birthDate: '',
    address: '',
    postalCode: '',
    city: '',
    email: '',
    mobile: '',
    boatName: '',
    boatRegNr: '',
    insuranceCompany: '',
    emergencyContact: '',
    emergencyMobile: '',
    comment: '',
    confirmRules: false,
    type: 'becomemember'
  });

  const [captcha, setCaptcha] = useState(generateCaptcha());
  const [captchaInput, setCaptchaInput] = useState('');
  const [messageStatus, setMessageStatus] = useState({ type: '', message: '' });

  const handleInputChange = (e) => {
    const { name, type, value, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (captchaInput !== captcha) {
      setMessageStatus({ type: 'error', message: 'Feil kode. Prøv igjen.' });
      return;
    }

    const timestamp = Date.now().toString();

    let signature;
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/form/signature/${timestamp}`);
      const data = await response.json();
      signature = data.signature;
    } catch (err) {
      console.error('Feil ved henting av signatur:', err);
      setMessageStatus({ type: 'error', message: 'Kunne ikke hente signatur. Prøv igjen senere.' });
      return;
    }

    const dateObj = new Date();
    const formattedDate = `${String(dateObj.getDate()).padStart(2, '0')}.${String(dateObj.getMonth() + 1).padStart(2, '0')}.${dateObj.getFullYear()} - ${String(dateObj.getHours()).padStart(2, '0')}:${String(dateObj.getMinutes()).padStart(2, '0')}`;

    const sanitizedName = formData.firstName.trim().replace(/\s+/g, '_').replace(/[^a-zA-Z0-9_æøåÆØÅ-]/g, '');
    const pdfFileName = `medlemskap_${sanitizedName}_${formattedDate.replace(/[^\d]/g, '')}.pdf`;

    const updatedFormData = {
      ...formData,
      date: formattedDate
    };

    const payload = {
      name: `${formData.firstName} ${formData.lastName}`,
      email: formData.email,
      type: 'becomemember',
      formData: updatedFormData,
      timestamp,
      signature,
      pdfFileName
    };

    try {
      await fetch(`${process.env.REACT_APP_BACKEND_URL}/form`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
      });

      setMessageStatus({ type: 'success', message: 'Søknaden er sendt!' });

      setFormData({
        firstName: '',
        lastName: '',
        birthDate: '',
        address: '',
        postalCode: '',
        city: '',
        email: '',
        mobile: '',
        boatName: '',
        boatRegNr: '',
        insuranceCompany: '',
        emergencyContact: '',
        emergencyMobile: '',
        comment: '',
        confirmRules: false
      });
      setCaptcha(generateCaptcha());
      setCaptchaInput('');
    } catch (err) {
      console.error('Feil ved innsending:', err);
      setMessageStatus({ type: 'error', message: 'Noe gikk galt. Prøv igjen senere.' });
    }
  };

  return (
    <Paper elevation={3} style={{ padding: '32px', marginTop: '32px' }}>
      <Typography variant="h5" gutterBottom>
        Søknad om medlemskap
      </Typography>
      <Typography variant="body1" gutterBottom>
        Jeg søker herved om medlemskap i Skodje Småbåtforening og erklærer med dette at jeg til enhver tid vil følge gjeldende regler og vedtekter i foreningen.
      </Typography>

      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}><TextField name="firstName" label="Fornavn" fullWidth required value={formData.firstName} onChange={handleInputChange} /></Grid>
          <Grid item xs={12} sm={6}><TextField name="birthDate" label="Fødselsdato" fullWidth required value={formData.birthDate} onChange={handleInputChange} /></Grid>
          <Grid item xs={12} sm={6}><TextField name="lastName" label="Etternavn" fullWidth required value={formData.lastName} onChange={handleInputChange} /></Grid>
          <Grid item xs={12} sm={6}><TextField name="boatName" label="Båtens navn" fullWidth value={formData.boatName} onChange={handleInputChange} /></Grid>
          <Grid item xs={12} sm={6}><TextField name="address" label="Adresse" fullWidth required value={formData.address} onChange={handleInputChange} /></Grid>
          <Grid item xs={12} sm={6}><TextField name="boatRegNr" label="Båtens reg.nr" fullWidth value={formData.boatRegNr} onChange={handleInputChange} /></Grid>
          <Grid item xs={12} sm={6}><TextField name="postalCode" label="Postnr" fullWidth required value={formData.postalCode} onChange={handleInputChange} /></Grid>
          <Grid item xs={12} sm={6}><TextField name="insuranceCompany" label="Båtens forsikringsselskap" fullWidth value={formData.insuranceCompany} onChange={handleInputChange} /></Grid>
          <Grid item xs={12}><TextField name="city" label="Sted" fullWidth required value={formData.city} onChange={handleInputChange} /></Grid>
          <Grid item xs={12}><TextField name="email" label="E-post" fullWidth required value={formData.email} onChange={handleInputChange} /></Grid>
          <Grid item xs={12} sm={6}><TextField name="mobile" label="Mobil" fullWidth required value={formData.mobile} onChange={handleInputChange} /></Grid>
          <Grid item xs={12} sm={6}><TextField name="emergencyContact" label="Nærmeste pårørende" fullWidth value={formData.emergencyContact} onChange={handleInputChange} /></Grid>
          <Grid item xs={12} sm={6}><TextField name="emergencyMobile" label="Mobil (pårørende)" fullWidth value={formData.emergencyMobile} onChange={handleInputChange} /></Grid>
          <Grid item xs={12}><TextField name="comment" label="Kommentar" fullWidth value={formData.comment} onChange={handleInputChange} /></Grid>
          <Grid item xs={12}>
            <label style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <input type="checkbox" name="confirmRules" checked={formData.confirmRules} onChange={handleInputChange} required />
              Jeg erklærer med dette at jeg til enhver tid vil følge gjeldende regler og vedtekter i foreningen.
            </label>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" gutterBottom>
              For å unngå spam må du skrive inn koden under eksakt slik den står:
            </Typography>
            <Typography variant="h6" gutterBottom style={{ fontFamily: 'monospace', backgroundColor: '#f0f0f0', padding: '8px', display: 'inline-block' }}>
              {captcha}
            </Typography>
            <TextField label="Eksakt kode som står over" fullWidth value={captchaInput} onChange={(e) => setCaptchaInput(e.target.value)} required style={{ marginTop: '8px' }} />
          </Grid>
        </Grid>

        <Button type="submit" variant="contained" color="primary" style={{ marginTop: '16px' }}>
          Send søknad
        </Button>

        {messageStatus.message && (
          <Alert severity={messageStatus.type} style={{ marginTop: '16px' }}>
            {messageStatus.message}
          </Alert>
        )}

        <Typography variant="body2" style={{ marginTop: '24px', fontWeight: 600 }}>
          NB! Dersom du bor utenfor Skodje, bør du oppgi grunnen til / ev. hvilken tilknytning du har til området når du nå søker medlemskap i foreningen.
        </Typography>
      </form>
    </Paper>
  );
};

export default BecomeMemberForm;
