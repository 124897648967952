import React, { useState } from 'react';
import {
  TextField,
  Grid,
  Paper,
  Typography,
  Alert,
  Button,
  FormControlLabel,
  Checkbox
} from '@mui/material';

const generateCaptcha = () => {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let captcha = '';
  for (let i = 0; i < 6; i++) {
    captcha += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return captcha;
};

const StorageHallForm = () => {
  const [formData, setFormData] = useState({
    boatName: '',
    regNr: '',
    weight: '',
    length: '',
    width: '',
    ownWagon: false,
    needEquipment: false,
    ownerName: '',
    address: '',
    phone: '',
    email: '',
    partnerName: '',
    partnerPhone: '',
    insurance: '',
    agreedToTerms: false,
    comment: '',
    type: 'storagehall'
  });

  const [captcha, setCaptcha] = useState(generateCaptcha());
  const [captchaInput, setCaptchaInput] = useState('');
  const [messageStatus, setMessageStatus] = useState({ type: '', message: '' });

  const handleInputChange = (e) => {
    const { name, type, value, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (captchaInput !== captcha) {
      setMessageStatus({ type: 'error', message: 'Feil kode. Prøv igjen.' });
      return;
    }
  
    const timestamp = Date.now().toString();
  
    let signature;
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/form/signature/${timestamp}`);
      const data = await response.json();
      signature = data.signature;
    } catch (err) {
      console.error('Feil ved henting av signatur:', err);
      setMessageStatus({ type: 'error', message: 'Kunne ikke hente signatur. Prøv igjen senere.' });
      return;
    }
  
    const dateObj = new Date();
    const formattedDate = `${String(dateObj.getDate()).padStart(2, '0')}.${String(dateObj.getMonth() + 1).padStart(2, '0')}.${dateObj.getFullYear()} - ${String(dateObj.getHours()).padStart(2, '0')}:${String(dateObj.getMinutes()).padStart(2, '0')}`;
    const dateForFilename = `${String(dateObj.getDate()).padStart(2, '0')}.${String(dateObj.getMonth() + 1).padStart(2, '0')}.${dateObj.getFullYear()}`;
    
    const sanitizedName = formData.ownerName.trim().replace(/\s+/g, '_').replace(/[^a-zA-Z0-9_æøåÆØÅ-]/g, '');
    const pdfFileName = `lagringshall_${sanitizedName}_${dateForFilename}.pdf`;    
  
    const updatedFormData = {
      ...formData,
      date: formattedDate
    };    

    const payload = {
      name: formData.ownerName,
      email: formData.email,
      type: 'storagehall',
      formData: updatedFormData,
      timestamp,
      signature,
      pdfFileName
    };
  
    try {
      await fetch(`${process.env.REACT_APP_BACKEND_URL}/form`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
      });
  
      setMessageStatus({ type: 'success', message: 'Søknaden din er sendt!' });
      setFormData({
        boatName: '',
        regNr: '',
        weight: '',
        length: '',
        width: '',
        ownWagon: false,
        needEquipment: false,
        ownerName: '',
        address: '',
        phone: '',
        email: '',
        partnerName: '',
        partnerPhone: '',
        insurance: '',
        agreedToTerms: false,
        comment: ''
      });
      setCaptcha(generateCaptcha());
      setCaptchaInput('');
    } catch (err) {
      console.error('Feil ved innsending:', err);
      setMessageStatus({ type: 'error', message: 'Noe gikk galt. Prøv igjen senere.' });
    }
  };  

  return (
    <Paper elevation={3} style={{ padding: '32px', marginTop: '32px' }}>
      <Typography variant="h5" gutterBottom>
        Søknad vinterlagring havnehallen
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={6}><TextField label="Båtnavn" name="boatName" fullWidth value={formData.boatName} onChange={handleInputChange} /></Grid>
          <Grid item xs={6}><TextField label="Reg.nr" name="regNr" fullWidth value={formData.regNr} onChange={handleInputChange} /></Grid>
          <Grid item xs={4}><TextField label="Vekt (kg)" name="weight" fullWidth value={formData.weight} onChange={handleInputChange} /></Grid>
          <Grid item xs={4}><TextField label="Lengde (fot)" name="length" fullWidth value={formData.length} onChange={handleInputChange} /></Grid>
          <Grid item xs={4}><TextField label="Bredde (m)" name="width" fullWidth value={formData.width} onChange={handleInputChange} /></Grid>

          <Grid item xs={6}>
            <FormControlLabel
              control={<Checkbox name="ownWagon" checked={Boolean(formData.ownWagon)} onChange={handleInputChange} />}
              label="Bruker egen båtvogn"
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={<Checkbox name="needEquipment" checked={Boolean(formData.needEquipment)} onChange={handleInputChange} />}
              label="Trenger havnas utstyr"
            />
          </Grid>

          <Grid item xs={12}><TextField label="Navn (båteier)" name="ownerName" fullWidth value={formData.ownerName} onChange={handleInputChange} /></Grid>
          <Grid item xs={12}><TextField label="Adresse" name="address" fullWidth value={formData.address} onChange={handleInputChange} /></Grid>
          <Grid item xs={6}><TextField label="Mobil" name="phone" fullWidth value={formData.phone} onChange={handleInputChange} /></Grid>
          <Grid item xs={6}><TextField label="E-post" name="email" fullWidth value={formData.email} onChange={handleInputChange} /></Grid>
          <Grid item xs={6}><TextField label="Ektefelle/samboer" name="partnerName" fullWidth value={formData.partnerName} onChange={handleInputChange} /></Grid>
          <Grid item xs={6}><TextField label="Mobil (samboer)" name="partnerPhone" fullWidth value={formData.partnerPhone} onChange={handleInputChange} /></Grid>

          <Grid item xs={12}><TextField label="Forsikringsselskap" name="insurance" fullWidth value={formData.insurance} onChange={handleInputChange} /></Grid>

          <Grid item xs={12}><TextField label="Kommentarer" name="comment" fullWidth value={formData.comment} onChange={handleInputChange} /></Grid>

          <Grid item xs={12}><TextField label="Når forventer du at båten skal ut av hallen?" name="expectboatout" fullWidth value={formData.expectboatout} onChange={handleInputChange} /></Grid>

          <Grid item xs={12}>
            <Typography>For å unngå spam, skriv inn denne koden:</Typography>
            <Typography variant="h6" style={{ fontFamily: 'monospace', backgroundColor: '#f0f0f0', display: 'inline-block', padding: '8px' }}>{captcha}</Typography>
            <TextField label="Skriv inn koden" fullWidth value={captchaInput} onChange={(e) => setCaptchaInput(e.target.value)} style={{ marginTop: '8px' }} required />
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  name="agreedToTerms"
                  checked={Boolean(formData.agreedToTerms)}
                  onChange={handleInputChange}
                  required
                />
              }
              label="Jeg bekrefter at båten er forsikret, at gass/bensintanker blir fjernet og at hovedstrømbryter blir avslått eller batteri frakoblet."
            />
          </Grid>
        </Grid>
        <Button type="submit" variant="contained" color="primary" style={{ marginTop: '16px' }}>
          Send søknad
        </Button>
        {messageStatus.message && (
          <Alert severity={messageStatus.type} style={{ marginTop: '16px' }}>
            {messageStatus.message}
          </Alert>
        )}
      </form>
    </Paper>
  );
};

export default StorageHallForm;
