import React from 'react';
import PropTypes from 'prop-types';
import '../styles/Parameter.css';

const Parameter = ({ icon, label, data, onFetchTrend }) => {

  const handleClick = (location, type) => {
    onFetchTrend(location, type);
  };

  const isOlderThanXMinutes = (dateStr) => {
    if (!dateStr) return false;

    const now = new Date();
    const [hours, minutes] = dateStr.split(':').map(Number);
    const date = new Date();
    date.setHours(hours, minutes, 0, 0);
    const diff = now - date;
    return diff > 5 * 60 * 1000; // Declare station dead if timestamp is older than 5 minutes
  };

  return (
    <div className={`parameter ${label === 'Sanntid vind nå (middelvind):' ? 'currwind-parameter' : ''}`}>
      <div className="icon-and-label">
        <i className={icon} aria-hidden="true"></i>
        <span>{label}</span>
      </div>
      <div className="data-grid">
        {data.map(({ className, value, imgSrc, bearing, description, date, isLast }, index) => (
          <div className={`data-row ${className === 'currwind' ? 'currwind-row' : ''}`} key={index}>
            {imgSrc ? (
              <span
                className={`${className} data-value ${isOlderThanXMinutes(date) ? 'old-data' : ''}`}
                role="button"
                tabIndex="0"
                onClick={() => handleClick('Hahjem', className)}
                onKeyDown={(e) => e.key === 'Enter' && handleClick('Hahjem', className)}
              >
                <div className="wind-container">
                  <img
                    className="wind-icon"
                    src={imgSrc}
                    alt={description}
                    style={{ transform: `rotate(${bearing}deg)` }}
                  />
                  <b className="description">{description}</b>
                </div>
                <b className="value">{value}</b>
              </span>
            ) : (
              <span
                className={`${className} data-value ${isOlderThanXMinutes(date) ? 'old-data' : ''}`}
                role="button"
                tabIndex="0"
                onClick={() => handleClick('Hahjem', className)}
                onKeyDown={(e) => e.key === 'Enter' && handleClick('Hahjem', className)}
              >
                <b>{value}</b>
              </span>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

Parameter.propTypes = {
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({
    className: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    imgSrc: PropTypes.string,
    bearing: PropTypes.string,
    description: PropTypes.string,
    date: PropTypes.string,
    isLast: PropTypes.bool
  })).isRequired,
  onFetchTrend: PropTypes.func.isRequired
};

export default Parameter;
