import React, { useState } from 'react';
import axios from 'axios';
import WindAlertForm from './WindAlertForm';

const generateCaptcha = () => {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let captcha = '';
  for (let i = 0; i < 6; i++) {
    captcha += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return captcha;
};

const WindAlertWrapper = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    type: 'windalert'
  });

  const [captcha, setCaptcha] = useState(generateCaptcha());
  const [captchaInput, setCaptchaInput] = useState('');
  const [messageStatus, setMessageStatus] = useState({ type: '', message: '' });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleCaptchaChange = (e) => {
    setCaptchaInput(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (captchaInput !== captcha) {
      setMessageStatus({ type: 'error', message: 'Feil kode. Vennligst prøv igjen.' });
      return;
    }

    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/wind-alert/subscribe`, {
        name: formData.name,
        email: formData.email
      });

      setMessageStatus({ type: 'success', message: 'Du er nå registrert for varsling ved sterk vind.' });
      setFormData({ name: '', email: '', type: 'windalert' });
      setCaptcha(generateCaptcha());
      setCaptchaInput('');
    } catch (err) {
      console.error('Feil ved innsending:', err);
      setMessageStatus({
        type: 'error',
        message: 'Kunne ikke registrere deg. Vennligst prøv igjen senere.'
      });
    }
  };

  return (
    <WindAlertForm
      formData={formData}
      onChange={handleInputChange}
      onSubmit={handleSubmit}
      messageStatus={messageStatus}
      captcha={captcha}
      captchaInput={captchaInput}
      onCaptchaInputChange={handleCaptchaChange}
    />
  );
};

export default WindAlertWrapper;
