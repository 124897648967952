import React, { useState } from 'react';
import axios from 'axios';
import BecomeMemberForm from './BecomeMemberForm';

const generateCaptcha = () => {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let captcha = '';
  for (let i = 0; i < 6; i++) {
    captcha += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return captcha;
};

const BecomeMemberWrapper = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    birthDate: '',
    lastName: '',
    boatName: '',
    address: '',
    boatRegNr: '',
    postalCode: '',
    insuranceCompany: '',
    city: '',
    email: '',
    phone: '',
    mobile: '',
    emergencyContact: '',
    emergencyMobile: '',
    date: '',
    place: '',
    signature: '',
    comment: '',
    confirmRules: false,
    type: 'becomemember'
  });

  const [captcha, setCaptcha] = useState(generateCaptcha());
  const [captchaInput, setCaptchaInput] = useState('');
  const [messageStatus, setMessageStatus] = useState({ type: '', message: '' });

  const handleInputChange = (e) => {
    const { name, value, type: inputType, checked } = e.target;
    const newValue = inputType === 'checkbox' ? checked : value;
    setFormData(prev => ({ ...prev, [name]: newValue }));
  };

  const handleCaptchaChange = (e) => {
    setCaptchaInput(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (captchaInput !== captcha) {
      setMessageStatus({ type: 'error', message: 'Feil kode. Vennligst prøv igjen.' });
      return;
    }

    const fullName = `${formData.firstName} ${formData.lastName}`.trim();
    const timestamp = Date.now().toString();
    let signature = '';

    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/form/signature/${timestamp}`);
      signature = response.data.signature;
    } catch (err) {
      console.error('Kunne ikke hente signatur fra backend:', err.message || err);
      setMessageStatus({ type: 'error', message: 'Intern feil. Prøv igjen senere.' });
      return;
    }

    const today = new Date().toISOString().split('T')[0];
    const sanitizedName = fullName.replace(/\s+/g, '_').replace(/[^a-zA-Z0-9_æøåÆØÅ-]/g, '');
    const pdfFileName = `medlemssøknad_${sanitizedName}_${today}.pdf`;

    const payload = {
      name: fullName,
      email: formData.email,
      type: formData.type,
      formData,
      timestamp,
      signature,
      pdfFileName
    };

    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/form`, payload);
      setMessageStatus({ type: 'success', message: 'Søknaden din har blitt sendt!' });
      setFormData(prev => ({
        ...Object.fromEntries(Object.keys(prev).map(key => [key, typeof prev[key] === 'boolean' ? false : ''])),
        type: 'becomemember'
      }));
      setCaptcha(generateCaptcha());
      setCaptchaInput('');
    } catch (err) {
      console.error('Feil ved innsending:', err);
      setMessageStatus({ type: 'error', message: 'Kunne ikke sende søknaden. Vennligst prøv igjen senere.' });
    }
  };

  return (
    <BecomeMemberForm
      formData={formData}
      onChange={handleInputChange}
      onSubmit={handleSubmit}
      messageStatus={messageStatus}
      captcha={captcha}
      captchaInput={captchaInput}
      onCaptchaInputChange={handleCaptchaChange}
    />
  );
};

export default BecomeMemberWrapper;
