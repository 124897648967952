import React, { useState } from 'react';
import axios from 'axios';
import StorageHallForm from './StorageHallForm';

const generateCaptcha = () => {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let captcha = '';
  for (let i = 0; i < 6; i++) {
    captcha += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return captcha;
};

const StorageHallWrapper = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
    boatType: '',
    regNr: '',
    dimensions: '',
    engine: '',
    insurance: '',
    comments: '',
    type: 'storagehall'
  });

  const [captcha, setCaptcha] = useState(generateCaptcha());
  const [captchaInput, setCaptchaInput] = useState('');
  const [messageStatus, setMessageStatus] = useState({ type: '', message: '' });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleCaptchaChange = (e) => {
    setCaptchaInput(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (captchaInput !== captcha) {
      setMessageStatus({ type: 'error', message: 'Feil kode. Vennligst prøv igjen.' });
      return;
    }

    const timestamp = Date.now().toString();
    let signature = '';

    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/form/signature/${timestamp}`);
      signature = response.data.signature;
    } catch (err) {
      console.error('Kunne ikke hente signatur fra backend:', err.message || err);
      setMessageStatus({
        type: 'error',
        message: 'Intern feil. Prøv igjen senere.'
      });
      return;
    }

    const today = new Date().toISOString().split('T')[0];
    const sanitizedName = formData.fullName
      .replace(/\s+/g, '_')
      .replace(/[^a-zA-Z0-9_æøåÆØÅ-]/g, '');
    const pdfFileName = `lagringshall_${sanitizedName}_${today}.pdf`;

    const payload = {
      name: formData.fullName,
      email: formData.email,
      type: formData.type,
      formData,
      timestamp,
      signature,
      pdfFileName
    };

    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/form`, payload);
      setMessageStatus({ type: 'success', message: 'Søknaden din er sendt!' });
      setFormData({
        fullName: '',
        email: '',
        phone: '',
        boatType: '',
        regNr: '',
        dimensions: '',
        engine: '',
        insurance: '',
        comments: '',
        type: 'storagehall'
      });
      setCaptcha(generateCaptcha());
      setCaptchaInput('');
    } catch (err) {
      console.error('Feil ved innsending:', err.response || err.message);
      setMessageStatus({ type: 'error', message: 'Noe gikk galt. Prøv igjen senere.' });
    }
  };

  return (
    <StorageHallForm
      formData={formData}
      onChange={handleInputChange}
      onSubmit={handleSubmit}
      messageStatus={messageStatus}
      captcha={captcha}
      captchaInput={captchaInput}
      onCaptchaInputChange={handleCaptchaChange}
    />
  );
};

export default StorageHallWrapper;
